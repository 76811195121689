<template>
    <div>
        <Xheader title="Family Account Book" imgurl="home1.png"></Xheader>
        <!--图片广告-->
        <b-container fluid="" class="p-1 mt-1">
            <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    indicators
                    background="#ababab"
                    img-width="1024"
                    img-height="480"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
            >
                <!-- Text slides with image -->
                <b-carousel-slide
                        caption="智能家庭账单系统"
                        text="家庭账目一目了然.（测试版）"
                        img-src="https://picsum.photos/1024/480/?image=52"
                ></b-carousel-slide>
            </b-carousel>
        </b-container>
        <!--公告-->
        <b-container fluid="" class="p-1">
            <b-card no-body>
                <b-card-header class="bgthemeColor textthemeColor fsize1"><img :src="ggImg" class="ggimgkz"/>通知中心
                </b-card-header>
                <b-card-body>
                    <b-list-group>
                        <b-list-group-item v-for="(item, i) in notices" :key="i" @click="showSomething(item.noticeTitle, item.noticeContent)">
<!--                            <b-badge class="mr-1" variant="danger">New</b-badge>-->
                            {{item.noticeTitle}}<span class="ml-1 text-muted float-right">{{item.rdDate}}</span>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-body>
            </b-card>
        </b-container>
<!--        <p class="text-danger p-2">此页所有功能都暂未具体实现，仅仅做了布局，功能正在开发中</p>-->
        <b-container class="p-1">
            <el-date-picker
                    v-model="date"
                    type="month"
                    placeholder="筛选指定日期"
                    @change="doSelect"
            >
            </el-date-picker>
            <div class="mt-1">
                <div>
                    <el-radio-group v-model="peopleSelect" size="small" fill="#91cc75" @change="doSelect">
                        <el-radio-button v-for="(item, i) in fayNs" :label="item" :key="i"></el-radio-button>
                    </el-radio-group>
                </div>
                <div>
                    <el-radio-group v-model="dateSelect" size="small" fill="#91cc75">
                        <el-radio-button label="这一年"></el-radio-button>
                        <el-radio-button label="这个月"></el-radio-button>
                    </el-radio-group>
                </div>
                <div>
                    <el-radio-group v-model="szSelect" size="small" fill="#91cc75">
                        <el-radio-button label="收入"></el-radio-button>
                        <el-radio-button label="支出"></el-radio-button>
                    </el-radio-group>
                </div>
            </div>
        </b-container>
        <b-container class="p-1">
            <b-card no-body>
                <b-card-header class="bgthemeColor textthemeColor fsize1"><img :src="billImg" class="ggimgkz"/>收支总览 v1
                </b-card-header>
                <b-card-body>
                    <el-table
                            :summary-method="getSummaries"
                            :data="tableData"
                            stripe
                            style="width: 100%"
                            max-height="300"
                            show-summary
                            :cell-class-name="summaryStyle"
                    >
                        <el-table-column
                                prop="date"
                                label="日期"
                                align="center"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="earn"
                                label="收入"
                                sortable
                                align="center"
                        >
                            <template slot-scope="scope">
                                <div class="text-success">￥{{Math.round(scope.row.earn)}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="cost"
                                label="支出"
                                sortable
                                align="center"
                        >
                          <template slot-scope="scope">
                            <div class="text-danger">￥{{Math.round(scope.row.cost)}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                                prop="balance"
                                label="余额"
                                sortable
                                align="center"
                        >
                            <template slot-scope="scope">
                                <div class="text-danger" v-if="scope.row.balance < 0">￥{{Math.round(scope.row.balance)}}</div>
                                <div class="text-success" v-else>￥{{Math.round(scope.row.balance)}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </b-card-body>
            </b-card>
        </b-container>
<!--        <b-container class="bgthemeColor textthemeColor m-1 mt-3">收支总览 v2</b-container>-->
<!--        <b-container ref="echarts" class="echarts m-1 mt-0"></b-container>-->
<!--        <b-container class="bgthemeColor textthemeColor m-1 mt-3">家庭成员月收入</b-container>-->
<!--        <b-container ref="pinEcharts" class="echarts m-1 mt-0"></b-container>-->
        <b-modal v-model="modalShow" :title="modalTitle">
            <p class="p-2" v-text="modalContent"></p>
        </b-modal>

    </div>
</template>

<script>

    export default {
        name: "Home",
        data() {
            return {
                modalShow: false,
                modalContent: '',
                modalTitle: '',
                options:{
                    title: {
                        text: 'ECharts 入门示例'
                    },
                    tooltip: {},
                    legend: {
                        data:['销量']
                    },
                    xAxis: {
                        data: ["衬衫","羊毛衫","雪纺衫","裤子","高跟鞋","袜子"]
                    },
                    yAxis: {},
                    series: [{
                        name: '销量',
                        type: 'bar',
                        data: [5, 20, 36, 10, 10, 20]
                    }]
                },
                xData: [],
                y1Data: [],
                y2Data: [],
                notices: [],
                fayNs: [],
                fayUsersInfo: [],
                userInfo: {},
                slide: 0,
                sliding: null,
                date: '2021-01',
                dateSelect: '这一年',
                szSelect: '支出',
                peopleSelect: '',
                homeImg: require('../assets/img/home1.png'),
                homeImg1: require('../assets/img/home.png'),
                billImg: require('../assets/img/bill1.png'),
                ggImg: require('../assets/img/gg.png'),
                tableData: []
            }
        },
        created() {
            this.date = new Date().getFullYear() + "-01"
            this.userInfo = this.$store.getters.getUserInfo.userInfo
            this.peopleSelect = this.userInfo.name
            this.getFamilyMs()
            this.getNotice()
        },
        methods: {
          summaryStyle({row, rowIndex}){
            console.log("开始")
            console.log(row)
            console.log(rowIndex)
            console.log("jieshu")
          },
          getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
              if (index === 0) {
                sums[index] = '总计';
                return;
              }
              console.log("data",data)
              const values = data.map(item => Number(item[column.property]));
              if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
                console.log(sums[index], "index")
                sums[index] = '￥' + Math.round(sums[index]);
              } else {
                sums[index] = 'N/A';
              }
            });
            return sums;
          },
            initEcharts () {
                var myEcharts = this.$echarts.init(this.$refs.echarts)
                var option = {
                    grid: {
                      left: "10%"
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: this.xData,
                        itemStyle: {},
                        axisLabel: {
                            interval: 0,
                            rotate: 45,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            formatter: (value) => {
                                return value/1000 + 'k';
                            }

                        }
                    },
                    legend: {},
                    series: [
                        {
                            name: '收入',
                            type: 'line',
                            data: this.y1Data,
                            itemStyle: {
                              color: '#00ff29'
                            },
                            lineStyle: {
                                color: '#00ff29'
                            }
                        },{
                            name: '支出',
                            type: 'line',
                            data: this.y2Data,
                            itemStyle: {
                                color: '#ff0000'
                            },
                            lineStyle: {
                                color: '#ff0000'
                            }
                        },
                    ]
                }
                myEcharts.setOption(option)
            },
            initEchartsPin () {
                var myEcharts1 = this.$echarts.init(this.$refs.pinEcharts)
                var option = {
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                normal: {
                                    position: 'inner',
                                    show: true,
                                    textStyle : {
                                        fontWeight : 600 ,
                                        fontSize : 15,
                                        color: '#fff'
                                    },
                                    formatter: '{d}%'
                                },
                                show: false,
                                position: 'center'
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: 1048, name: '刘兆新'},
                                {value: 735, name: '刘涵'},
                                {value: 580, name: '付世玲'},
                                {value: 484, name: '萧然'},
                            ]
                        }
                    ]
                };
                myEcharts1.setOption(option)
            },
            showSomething(title, content){
                // this.$alert(content, title, {
                //     confirmButtonText: '确定',
                // });
                this.modalTitle = title
                this.modalContent = content
                this.modalShow = true
            },
            async getNotice(){
                const rst = await this.axios.get("/notice")
                this.notices = rst.data
            },
            doSelect(){
                this.showBills()
            },
            async getFamilyMs(){
                const key =  this.userInfo.pkey
                const rst = await this.axios.get("/getFamilyMs?pkey="+key)
                this.fayNs = rst.data.data[1]
                this.fayUsersInfo = rst.data.data[0]
                //先得到家庭成员之后再加载数据
                this.showBills()
            },
            async showBills(){
                //得到 phone及密码
                const that=this
                const cuserInfo = this.fayUsersInfo.find(function (user) {
                    return user.name === that.peopleSelect;
                })
                var date = new Date(this.date);
                var parseDate = date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, "0")
              const rst = await this.axios.post("/showBills", "name="+cuserInfo.name + "&phone="+cuserInfo.sjh
              +"&password="+cuserInfo.pwd+"&date="+parseDate);
                this.tableData = Object.values(rst.data.data[0]);
                this.xData = Object.values(rst.data.data[1]);
                this.y1Data = Object.values(rst.data.data[2]);
                this.y2Data = Object.values(rst.data.data[3]);
                this.tableData.reverse()
                // this.initEcharts()
                // this.initEchartsPin()
                // 图表初始化
            },
            onSlideStart(slide) {
            console.log(slide)
                this.sliding = true
            },
            onSlideEnd(slide) {
              console.log(slide)
              this.sliding = false
            }
        }
    }
</script>

<style scoped>
.el-table__footer-wrapper tbody td.szTable{

}
    .ceshi{
        color: #1296db;
    }
    .ggimgkz {
        width: 25px;
        padding: 3px;
        margin-bottom: 4px;
        margin-right: 2px;
    }

    .echarts {
        height: 400px;
        background: white;
    }
</style>
